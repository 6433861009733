import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(axios, '/manager/accounts/:account_id/roles', params, query, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/accounts/:account_id/roles', params, payload, config)
  },

  searchOrganizations(axios, params, query, config) {
    return api.get(
      axios,
      '/manager/accounts/:account_id/roles/search_organizations',
      params,
      query,
      config
    )
  },
}
