<template lang="pug">
  b-card.manager-accounts-roles-form.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-form.form-wc.form-accounts-role-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            b-form-group#form-roles-global(
              :label="$t('role.global')"
              label-for='roles-global'
              label-cols-md='2'
              label-align-md='right')
              b-form-checkbox-group#roles-global(
                name='roles_global'
                v-model='rolesGlobalSelected'
                size='md'
                button-variant='primary'
                buttons)
                b-form-checkbox.shadow-none(
                  v-for='(option, index) in rolesGlobalOptions'
                  :key='index'
                  :value='option.value')
                  | {{ option.text }}

            b-row.mb-5
              b-col(offset-md='2')
                wc-icon-alert(
                  icon='fal fa-exclamation-triangle'
                  variant='warning'
                  :title="$t('role.alerts.global.title')"
                  :detail="$t('role.alerts.global.detail')")

            b-form-group#form-roles-admin_organizations(
              :label="`${$t('role.codes.admin_scp')} ${$t('shared.of')}`"
              label-for='roles-admin_organization_ids'
              label-cols-md='2'
              label-align-md='right')
              multiselect#roles-admin_organization_ids.form-input-first.shadow-sm.custom-select(
                name='admin_organizations'
                v-model='rolesAdminOrganizationIdsSelected'
                :multiple='true'
                :options='form.selects.rolesAdminOrganizationIdsOptions'
                :close-on-select='true'
                :clear-on-select='true'
                :placeholder="$t('role.placeholders.organizations')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.rolesAdminOrganizationIdsLoading'
                @search-change='searchOrganizations'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}

            b-form-group#form-roles-manager_organizations(
              :label="`${$t('role.codes.manager_scp')} ${$t('shared.of')}`"
              label-for='roles-manager_organization_ids'
              label-cols-md='2'
              label-align-md='right')
              multiselect#roles-manager_organization_ids.form-input-middle.shadow-sm.custom-select(
                name='manager_organizations'
                v-model='rolesManagerOrganizationIdsSelected'
                :multiple='true'
                :options='form.selects.rolesManagerOrganizationIdsOptions'
                :close-on-select='true'
                :clear-on-select='true'
                :placeholder="$t('role.placeholders.organizations')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.rolesManagerOrganizationIdsLoading'
                @search-change='searchOrganizations'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}

            b-form-group#form-roles-editor_organizations(
              :label="`${$t('role.codes.editor_scp')} ${$t('shared.of')}`"
              label-for='roles-editor_organization_ids'
              label-cols-md='2'
              label-align-md='right')
              multiselect#roles-editor_organization_ids.form-input-last.shadow-sm.custom-select(
                name='editor_organizations'
                v-model='rolesEditorOrganizationIdsSelected'
                :multiple='true'
                :options='form.selects.rolesEditorOrganizationIdsOptions'
                :close-on-select='true'
                :clear-on-select='true'
                :placeholder="$t('role.placeholders.organizations')"
                label='label'
                track-by='value'
                :internal-search='false'
                :selectLabel="$t('shared.forms.multiselect.select_label')"
                :selectedLabel="$t('shared.forms.multiselect.selected_label')"
                :deselectLabel="$t('shared.forms.multiselect.deselect_label')"
                :limitText="count => $t('shared.forms.multiselect.limit_text', { count: count })"
                :loading='form.selects.rolesEditorOrganizationIdsLoading'
                @search-change='searchOrganizations'
                @input='formSelectOption')
                span(slot='noResult')
                  | {{ $t('shared.forms.multiselect.no_result') }}
                span(slot='noOptions')
                  | {{ $t('shared.forms.multiselect.no_options_search') }}

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-roles-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/accounts/shared/tabs'
import WcIconAlert from '@components/shared/WcIconAlert'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiRoles from '@services/api/manager/accounts/roles'
import { Form, mapSelects } from '@common/form'

export default {
  name: 'manager-accounts-roles-form',
  mixins: [Tabs, Form],
  components: {
    WcIconAlert,
    WcFormsButtons,
  },
  computed: {
    ...mapSelects([
      {
        name: 'rolesGlobal',
        default: 'attributes.roles_global',
        attribute: 'attributes.roles_global',
        collection: {
          data: 'roles_global.data',
          key: 'id',
          value: 'attributes.name',
          options: { key_label: 'text', raw_ids: true },
        },
      },
      {
        name: 'rolesAdminOrganizationIds',
        default: 'relationships.admin_organizations.data',
        attribute: 'attributes.admin_organization_ids',
        included: { type: 'organization', key: 'id', value: 'attributes.id_humanized' },
        multiselect: { key: 'id' },
      },
      {
        name: 'rolesManagerOrganizationIds',
        default: 'relationships.manager_organizations.data',
        attribute: 'attributes.manager_organization_ids',
        included: { type: 'organization', key: 'id', value: 'attributes.id_humanized' },
        multiselect: { key: 'id' },
      },
      {
        name: 'rolesEditorOrganizationIds',
        default: 'relationships.editor_organizations.data',
        attribute: 'attributes.editor_organization_ids',
        included: { type: 'organization', key: 'id', value: 'attributes.id_humanized' },
        multiselect: { key: 'id' },
      },
    ]),
    apiParams() {
      return {
        get: [{ account_id: this.account_id }],
        update: [
          { account_id: this.account_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        rolesAdminOrganizationIdsSearch: [
          { account_id: this.account_id },
          { term: this.form.selects.rolesAdminOrganizationIdsTerm },
        ],
        rolesManagerOrganizationIdsSearch: [
          { account_id: this.account_id },
          { term: this.form.selects.rolesManagerOrganizationIdsTerm },
        ],
        rolesEditorOrganizationIdsSearch: [
          { account_id: this.account_id },
          { term: this.form.selects.rolesEditorOrganizationIdsTerm },
        ],
      }
    },
  },
  methods: {
    searchOrganizations(term, id) {
      this.formMultiselectSearch(term, id, 'searchOrganizations', 'id_humanized')
    },
    apiCallback(callback) {
      if (callback === 'submit-success') this.$emit('roles-changed')
    },
  },
  validations() {
    return {
      form: {
        data: {
          attributes: {
            roles_global: {},
            admin_organization_ids: {},
            manager_organization_ids: {},
            editor_organization_ids: {},
          },
        },
      },
    }
  },
  data() {
    return {
      apiBase: apiRoles,
      apiModel: 'roles',
      account_id: this.$route.params.account_id,
      asyncDataSelects: [
        'rolesAdminOrganizationIds',
        'rolesManagerOrganizationIds',
        'rolesEditorOrganizationIds',
      ],
    }
  },
}
</script>
